.e-nav-item-search {
     a {
        color: $gray-800 !important;
         padding: 0.25em .375em;

         .e-nav-pageicon {
             padding: 0.4em;
             border-radius: 100%;
             background: $white;
             box-shadow: 0px 1px 3px rgba(0,0,0, 0.3);
         }
         &:before {
             display: none;
         }


    }
}


