.e-footer {
    font-size: $font-size-sm;
}

.e-footer-content {
    @include e-bg($footer-bg, $footer-color);
    @include e-text($footer-color, $footer-headings-color, $footer-link-color);
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
    &.e-section {
        padding-right: 9vw;
        padding-left: 9vw;
        @include media-breakpoint-down(md) {
            padding-right: 2vw;
            padding-left: 2vw;
        }
    }
    svg {
        [id*="Filled_Icons"] {
            fill: $footer-color;
            opacity: 0.85;
        }
        [id*="Outline_Icons"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $footer-color;
                opacity: 0.85;
            }
        }
    }
    .form-control {
        background-color: transparent;
        border-radius: 0;
        border-width: 0;
        border-bottom: 1px solid #4F72A2;
        color: $white;
        font-size: 1em;
        padding: 0.75em 0.5em;

        &::placeholder {
            color: $white;
        }
    }

    .btn {
        background: transparent;
        border: 2px solid $white;
        font-size:$font-size-base;

        &:hover {
            background: $white;
        }
    }

    h3 {
        margin-bottom: 1.25em;
    }

    p {
        line-height: $line-height-lg * 1.2;
    }

    p a:not(.btn) {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    i.material-icons {
        transform: scale(1.25) translateY(0.25em);
    }


}
.e-footer-copyright {
    font-size: $font-size-sm;
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
    color: $footer-copyright-color;
    background: $footer-copyright-bg;
    p {
        margin: 0;
        color: $footer-copyright-color
    }
    a {
        color: $footer-copyright-link-color;
        &:hover {
            color: $footer-copyright-link-hover-color;
        }
    }
    .e-copyright {}
    .website-by-co3 {}
}
