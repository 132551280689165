// HEADER

.kronospan-utilities-primary {
    border-bottom: 1px solid $border-color;
}

.e-nav-utilities-primary > .nav > .nav-item > .nav-link {
    &:last-child {
        padding-right: $spacer / 2;
    }
}

.e-header.is-inverted {
    @include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {

        &.is-stuck {
            background: rgba($gray-500, 0.5);
        }
        .e-nav-global>.nav>.nav-item > .nav-link {
            color: $white;

            &::before {
                background: $gray-300;
            }

            &.is-inpath,
            &.is-active {
                &::before {
                    background: $white;
                }
            }
        }

        .kronospan-utilities-primary {
            border-bottom-color: $white;
        }

        .e-nav-utilities-primary > .nav > .nav-item > .nav-link {
            color: $white;
        }
    }


    @include media-breakpoint-down(breakpoint-next($nav-hamburger-breakpoint)) {

        &.is-stuck {
            background: rgba($gray-500, 0.5);
        }

        .e-nav-hamburger-header .nav-link,
        .e-nav-hamburger-header .e-nav-pageicon {
            color: $white;
        }
    }
}
