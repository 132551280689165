@include media-breakpoint-down($nav-hamburger-breakpoint) {
    // Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-global {
        &::before {
            content: "";
            display: block;
            margin: 0 (($canvas-padding-sm + e-strip-unit($grid-gutter-width/2)) * -1);
            border-top: $border-width solid $header-border-color;
        }
        font-size: 1.125em;

        background: $white;
        // All levels.
        .collapsing {
            display: block;
        }
        .nav {
            flex-direction: column;
            margin: 0 0 ($spacer * 0.675) ($spacer * 1.25);
        }
        .nav-item {
        }
        .nav-link {
            padding: ($spacer * 0.5) ($spacer * 0);
            color: $headings-color;
            background: none;
            white-space: normal;
            line-height: $line-height-sm;
            &.is-active {
                // font-weight: $font-weight-bold;
                // color: theme-color("primary");
            }
        }
        .e-nav-pageicon {
            display: none;
            + .e-nav-pagename {
                //margin-left: 0.875em;
            }
        }

        .e-nav-pageicon-dropdown {
            //display: none;
        }
        // First level only.
        > .nav {
            margin: $spacer 0 0;
            > .nav-item {
                opacity: 0;
                transform: translateX($spacer * -2);
                transition: all $transition-slide-speed $transition-slide-easing;
                $item-entry-delay: 0.05s;
                @for $item from 1 through 10 {
                    &:nth-child(#{$item}) {
                        transition-delay: $item-entry-delay * $item;
                    }
                }
                > .nav-link {
                    font-size: 1.125em;
                    padding: ($spacer * 0.375) 0;
                    font-weight: $font-weight-bold;
                }
            }
        }
        // Props for animation entry in active modal.
        .show & {
            // First level only.
            > .nav {
                > .nav-item {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .e-megamenu-container {
            .close {
                display: none;
            }
        }
        .e-megamenu {
            .container-fluid {
                padding: 0;
            }
            .row {
                margin: 0;
                display: block;
            }
            .e-megamenu-content {
                display: none;
            }
        }
        .e-megamenu-item {
            .e-megamenu-header {
            }
            .nav {
                .nav-item {
                }
                .nav-link {
                }
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    // Overrides to fix collapse vs. dropdown conflicts.
    .e-nav-global {
        .collapse {
            display: none;
        }
        .dropdown.show > .collapse,
        .e-dropdown-mega.show .collapse {
            display: block;
        }
    }
    // Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-global {
        // font-family: $font-family-monospace;
        display: flex;
        font-size: $h3-font-size;
        > .nav {
            display: flex;
            justify-content: center;
            align-items: stretch;
            > .nav-item {
                display: flex;
                align-items: stretch;
                padding: 0 ($spacer * 0.0625);
                > .nav-link {
                    display: flex;
                    align-items: center;
                    padding: ($spacer * 0.5) ($spacer * 0.75);
                    color: $header-color;
                    font-size: $font-size-sm;
                    line-height: $line-height-sm;
                    font-weight: $font-weight-bold;
                    position: relative;
                    &.is-inpath,
                    &.is-active {
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 0.25em;
                        background: currentColor;
                        transform: scaleX(0);
                    }
                    &:hover {
                        &::before {
                            transform: scaleX(1);
                            transition: transform $transition-slide-speed $transition-slide-easing;
                        }
                    }
                    &.is-inpath,
                    &.is-active {
                        &::before {
                            transform: scaleX(1);
                            background: theme-color("primary");
                        }
                    }
                    .e-nav-pageicon {
                        transform: scale(e-strip-unit($spacer));
                        + .e-nav-pagename {
                            margin-left: 0.875em;
                        }
                    }
                    .e-nav-pagename {
                        &.is-hidden {
                            display: none;
                            + .e-nav-pageicon-dropdown {
                                display: none;
                            }
                        }
                    }
                    .e-nav-pageicon-dropdown {

                    }
                }
                > .nav {
                    display: none;
                }
            }
        }
        .dropdown-menu {
            font-family: $font-family-base;
            border: 0;
            border-radius: 0 0 $border-radius $border-radius;
            margin-left: -1.25em;
            .dropdown-item {
                padding: 0;
                .nav-link {
                    padding: 0.5em 2.5em;
                    display: block;
                    color: inherit;
                }
            }
        }
        .e-dropdown-mega {
            position: static;
        }
        .e-megamenu-container {
            left: 0;
            right: 0;
            width: auto;
            max-height: 90vh;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0;
        }
        .e-megamenu {
            @include e-canvas-padding;
            max-width: $canvas-max-width;
            margin: 0 auto;
            padding: ($spacer * 1.5) ($canvas-padding-lg / 2) ($spacer * 2);
            .e-megamenu-content {
                font-size: $font-size-sm;
                padding-bottom: $spacer * 1.5;
                @include make-col-ready();
                @include make-col(12);
                @include media-breakpoint-up(md) {
                    @include make-col(10);
                    margin-right: percentage(( $grid-columns - 10) / 2 / $grid-columns);
                    margin-left: percentage(( $grid-columns - 10) / 2 / $grid-columns);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(8);
                    margin-right: percentage(( $grid-columns - 8) / 2 / $grid-columns);
                    margin-left: percentage(( $grid-columns - 8) / 2 / $grid-columns);
                }
                h1,
                h2,
                h3,
                h4,
                p {
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                }
                a {
                    color: inherit;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .e-megamenu-nav {
                @include make-col-ready();
                @include make-col(12);
                > .nav {
                    @include make-row();
                }
            }
            .e-megamenu-item {
                @include make-col-ready();
                @include make-col(3);
                .e-megamenu-header {
                    // font-family: $font-family-monospace;
                    font-size: $h3-font-size;
                    font-weight: $h3-font-weight;
                    color: e-section-color("light", "text");
                    margin-top: $headings-margin-top;
                    margin-bottom: $headings-margin-bottom / 2;
                    //text-transform: uppercase;
                    //opacity: .65;
                    pointer-events: none;
                }
                .nav {
                    .nav-item {
                        line-height: 1.375;
                    }
                    .nav-link {
                        display: block;
                        color: e-section-color("light", "text");
                        padding-left: 1.5em;
                        padding-top: 0.25em;
                        padding-bottom: 0.25em;
                        position: relative;
                        &::before {
                            content: '–';
                            color: e-section-color("light", "text");
                            opacity: $text-muted;
                            position: absolute;
                            left: 0;
                        }
                        &::after {
                            @include material-icons("arrow_forward");
                            color: $link-color;
                            width: 0;
                            opacity: 0;
                            transform: translateX(-1em);
                        }
                        &:hover {
                            color: $link-color;
                            &::after {
                                opacity: 1;
                                transform: translateX(0.5em);
                                transition: opacity $transition-fade-speed / 2 $transition-fade-easing, transform $transition-slide-speed / 2 $transition-slide-easing;
                            }
                        }
                    }
                }
            }
            &.e-megamenu-item-count-2 {
                .e-megamenu-item {
                    @include make-col(5);
                }
            }
            &.e-megamenu-item-count-3,
            &.e-megamenu-item-count-6 {
                .e-megamenu-item {
                    @include make-col(4);
                }
            }
            &.e-megamenu-item-count-5 {
                .e-megamenu-item {
                    @include make-col(2.4);
                }
            }
            &.e-megamenu-item-count-7,
            &.e-megamenu-item-count-8 {
                .e-megamenu-item {
                    @include make-col(2);
                }
            }
        }
    }
}
