@import "e-nav-item-search.scss";
@import "e-nav-utilities-primary.scss";
@import "kronospan-inverted-header.scss";
@import "e-paragraph-banner.scss";
@import "e-footer.scss";
@import "e-nav-global.scss";


.btn {
    min-width: 10em;
}


p.small, small {
    color: theme-color("primary");
}


// KRONOSPAN FORM

form.kronospan-form {
    margin-top: -0.25em;
    .form-control {
        background: transparent;
        border-width: 0;
        border-radius: 0;
        border-bottom: 1px solid $gray-400;
        padding: 0.75em .5em;
    }

    textarea {
        min-height: 8em;
        border: 1px solid $gray-400 !important;
    }
}

@media (min-width: 992px) {
    .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link {
        color: rgba(0, 0, 0, 0.65) !important;
    }
    .e-header.is-inverted .e-nav-global>.nav>.nav-item .nav-link:before {
        background: none;
    }
    .e-nav-global .dropdown-menu .dropdown-item .nav-link {
        color: rgba(0,0,0,.65)!important;
    }
    .e-nav-global .e-megamenu .e-megamenu-item .e-megamenu-header span.e-nav-pagename  {
        color: rgba(0,0,0,.65);
    }
}

