@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-nav-utilities-primary {
        .e-nav-pageicon {
            color: $white;
        }

        // Level #1
        > .nav {
            > .nav-item {
                &.dropdown {
                    display: flex;
                }
                > .nav-link {
                    
                    .e-nav-pagename {
                        &.is-hidden {
                            display: none;
                            + .e-nav-pageicon-dropdown {
                                display: inline-block;
                            }
                        }
                        + .e-nav-pageicon-dropdown {
                        }
                    }
                    .e-nav-pageicon-dropdown {
                    }
                }
            }

        }
    }
    
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-primary {
        padding-top: 0;
        // All levels

    }
}
